import React from 'react';
import gifImage3 from '../assets/4.png';


const WhoWeAre: React.FC = () => {
    return (
        <div
            className="flex flex-col relative md:flex-row items-center justify-center bg-red-200 py-28 px-6 md:px-20 space-y-6 md:space-y-0 md:space-x-8 font-halo-dek">
            {/* Left Side - Image with Frame and Tag */}
            <div className="relative">
                <div className="border-8 border-white  relative rounded-[12%] ">
                    <img
                        src={gifImage3}// Replace with your image path
                        alt="DUCK"
                        className="w-full h-full mx-auto rounded-[15%]"
                    />
                    {/* Frame Style */}
                    <div className="absolute inset-0 border-8 border-white rounded-[10%]"></div>
                </div>
                {/* Tag - About Us */}
                <div
                    className="absolute text-black top-0 left-0 -mt-6 -ml-8 bg-red-400 font-halo-dek font-bold px-4 py-1 rounded-full transform -rotate-12">
                    ABOUT
                </div>
            </div>

            {/* Right Side - Text Content */}
            <div className="text-center  text-black md:text-left space-y-4">
                <h2 className="text-5xl font-extrabold">Who is Nancy Parker</h2>
                <p className="text-xl tracking-wide  font-medium">
                    Where Nancy Parker secures the bag ($50K reward) and Luigi Mangione loses his mask and his freedom. From Ivy League genius to Starbucks Casanova to McDonald's mastermind, this token is a wild ride through the internet's favorite snitch saga. Join the chaos and cash in on the meme of the year! 🐀💸
                </p>
            </div>

            <div className=" rotate-[-3deg] h-16 bg-red-300 absolute bottom-0 left-[-4%] w-[110%]"></div>
            <div
                className=" rotate-[-3deg] h-16 bg-red-100 absolute bottom-[-54px] left-[-4%] w-[110%]"></div>
        </div>
    );
};

export default WhoWeAre;

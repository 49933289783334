import gifImage9 from '../assets/2.webp';
import React from "react";
import {FaRegCopy, FaTelegramPlane,} from "react-icons/fa";
import {FaX, FaXTwitter} from "react-icons/fa6";
import button from '../assets/button.svg';
import telesocial from '../assets/ducks/telegram.gif';
import xsocial from '../assets/ducks/x.gif';

const CommunitySection: React.FC = () => {
    const handleCopy = () => {
        const textToCopy =
            "CA: soon"; // Text to copy
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert("Copied to clipboard!");
        }).catch(() => {
            alert("Failed to copy!");
        });
    };
    const telegramRedirect = () => {
        window.open("https://t.me/nancyparkercto", "_blank", "noopener,noreferrer");
    };
    const xRedirect = () => {
        window.open("https://x.com/NancySnitched", "_blank", "noopener,noreferrer");
    };

    const dex = () => {
        window.open("https://dexscreener.com/solana/89KRrDxg3eJ95Yvwa9KR3U5M24avddChFMf3d4aFBhfQ", "_blank", "noopener,noreferrer");
    };
    const radium = () => {
        window.open("https://raydium.io/swap/?inputMint=sol&outputMint=5MrLrZEwRTRHJJGKnL6gvVhCD9dTjer3aSHadQPXpump", "_blank", "noopener,noreferrer");
    };
    return (
        <div
            className="bg-red-400 py-10 px-6 md:px-20 flex flex-col md:flex-row items-center pt-24 justify-between space-y-6 md:space-y-0 text-black">
            {/* Left Side - Logo and Name */}
            <div className="flex flex-col items-center md:items-start">
                <img
                    src={gifImage9} // Replace with your image path
                    alt="Suibara Logo"
                    className="w-6/12 mb-4"
                />

            </div>

            <div className="flex flex-col md:flex-row items-center justify-between w-full">
                {/* Center - Community Message */}
                <div className="text-center md:text-left space-y-4 max-w-md">
                    <p className="text-lg tracking-widest font-halo-dek text-black font-semibold">
                        The ultimate meme token of chaos! 🚨 Join the community and be part of the snitching revolution. 🐀👉
                    </p>
                    {/*<button*/}
                    {/*    className="bg-white text-black font-bold py-2 px-4 rounded-md shadow-lg transform hover:scale-105 transition-transform duration-200">*/}
                    {/*    Join the community*/}
                    {/*</button>*/}
                    <button onClick={telegramRedirect}>
                        <FaTelegramPlane className="w-6 h-6 mr-6" />
                    </button>
                    <button onClick={xRedirect}>

                        <FaXTwitter className="w-6 h-6" />
                    </button>
                </div>

                {/* Right Side - Social Media Icons */}
                <div className="flex flex-col items-center space-y-4">
                    <div className="relative flex flex-col items-end">

                        <div className="relative">
                            <button onClick={dex}
                                className=" relative font-halo-dek rounded transform skew-x-[10deg] z-10 bg-red-200 text-black font-bold py-2 px-8">
                                DexScreener
                            </button>
                            <button  onClick={radium}
                                className="mr-2 relative font-halo-dek rounded transform skew-x-[10deg] z-10 bg-red-500 text-black font-bold py-2 px-8">
                                Buy now
                            </button>
                        </div>

                        <div
                            className=" bg-red-100  rounded font-medium py-2 px-4 flex items-center shadow-md transform skew-x-[10deg]">
                            <span
                                className="flex-1 font-halo-dek whitespace-normal lg:whitespace-nowrap">CA: 5MrLrZEwRTRHJJGKnL6gvVhCD9dTjer3aSHadQPXpump</span>

                            <button className="ml-2" onClick={handleCopy}>
                                <FaRegCopy className="w-6 h-6" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CommunitySection;
